import { compact, fromPairs, keyBy } from 'lodash';
import { FS } from '../constants';
import { Doc, generateToDocFn } from '../types/Document';
import { UserPublic } from '../types/User';
import {
  CollectionListener,
  createSingleCollectionListenerStore,
  useCollectionListener
} from './collectionListener';
import { LoadingValue, store, useMappedLoadingValue } from './db';
import {
  createDocumentListenerGetter,
  useDocumentListener,
  useDocumentListenersFromStore
} from './documentListener';
import { IDENTITY } from './emptyConstants';

export const toUserName = (u: { firstName: string; lastName: string }) =>
  [u.firstName, u.lastName].filter(IDENTITY).join(' ');

export const usersPublicCollection = () => store().collection(FS.usersPublic);

export const toUserPublicDoc = generateToDocFn<UserPublic>((d) => {
  d.roles = d.roles || [];
  return d;
});

const getUserPublicListener = createSingleCollectionListenerStore(
  () => new CollectionListener(usersPublicCollection(), toUserPublicDoc)
);

export const usersPublicStore = createDocumentListenerGetter(
  (userId) => usersPublicCollection().doc(userId),
  toUserPublicDoc
);

export const useUsersPublic = () => {
  return useCollectionListener(getUserPublicListener());
};

export const useUserPublicById = (userId: string) => {
  return useDocumentListener(usersPublicStore(userId));
};

export const useUsersPublicByIds = (userIds: string[]) => {
  return useDocumentListenersFromStore(usersPublicStore, userIds);
};

export const useUserPublicMapByIds = (
  userIds: string[]
): LoadingValue<{
  [userId: string]: Doc<UserPublic> | null;
}> => {
  return useMappedLoadingValue(useUsersPublicByIds(userIds), (profiles) => {
    const byId = keyBy(compact(profiles), (u) => u.id);
    return fromPairs(userIds.map((userId) => [userId, byId[userId] || null]));
  });
};
