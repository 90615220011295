import {
  Button,
  Card,
  Typography,
  CircularProgress,
  TextField,
  Avatar,
  Step,
  Stepper,
  StepLabel,
  Dialog,
  DialogContent,
  ButtonBase
} from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Link } from 'gatsby';
import { capitalize } from 'lodash';
import axios from 'axios';
import React, { useState } from 'react';
import shortid from 'shortid';
import md5 from 'md5';
import { TrendingUp, MessageCircle, Key, Star, Video } from 'react-feather';
import { HighlightWhite } from './Highlight';
import { storage } from '../services/db';
import { WarningMessage } from '../components/Alerts';
import {
  COUNTRIES,
  CLOUD_FUNCTION_DOMAIN,
  FUNCTIONS,
  MONTHLY_SUBSCRIPTION_PRICE_DISCOUNT,
  STRIPE_PUBLIC_KEY,
  YEARLY_SUBSCRIPTION_PRICE_NORMAL,
  QUARTERLY_SUBSCRIPTION_PRICE_DISCOUNT,
  LIFETIME_SUBSCRIPTION_PRICE_NORMAL,
  COMPANY_SUBSCRIPTION_PRICE_NORMAL
} from '../constants';
import { login, resetPassword } from '../services/auth';
import { useCurrentUser } from '../services/currentUser';
import { updateName, updateAvatarUrl } from '../services/users';
import styled from '../styled';
import { useDialogState } from '../hooks/useDialogState';
import { ImageUploadDialog } from './ImageUpload';
import { ButtonWithPromise } from './ButtonWithPromise';
import { YourBlogs } from '../pages/profile';

let gtag: any;

if (typeof window !== 'undefined') {
  gtag = (window as any).gtag;
} else {
  gtag = () => {};
}

const StyledSelect = styled<'select', { value: string }>('select')`
  background-color: white;
  color: ${(p) => (p.value === '' ? '#767E87' : 'black')};
  padding: 8px;
  font-size: 1rem;
  height: 58px;
  width: 100%;
  border: 1px solid #767e87;
  border-radius: 8px;
  border: 1px solid ${(p) => p.theme.palette.grey[400]};
  border-radius: 8px;
`;

const Form = styled('form')`
  padding: 0;
  margin-top: ${(p) => p.theme.spacing(2)}px;
`;

type Plan =
  | 'free'
  | 'monthly'
  | 'quarterly'
  | 'annual'
  | 'lifetime'
  | 'company';
type State = 'initial' | 'success' | 'error';

const INITIAL_STATE = 'initial';
const INITIAL_EMAIL = '';

const toAnalyticsEvent = (plan: Plan) => {
  const label = capitalize(plan);
  const value: { [K in Plan]: number } = {
    free: 0,
    monthly: MONTHLY_SUBSCRIPTION_PRICE_DISCOUNT,
    annual: YEARLY_SUBSCRIPTION_PRICE_NORMAL,
    quarterly: QUARTERLY_SUBSCRIPTION_PRICE_DISCOUNT,
    lifetime: LIFETIME_SUBSCRIPTION_PRICE_NORMAL,
    company: COMPANY_SUBSCRIPTION_PRICE_NORMAL
  };
  return {
    event_category: 'Membership',
    event_label: label,
    event_value: value[plan]
  };
};

let STRIPE_PAYMENT_INTENT_SECRET = '';
let STRIPE_CUSTOMER_ID = '';

const CheckoutForm = ({
  plan,
  state,
  setState,
  setRandomPassword,
  email,
  setEmail
}: {
  plan: Plan;
  state: State;
  setState: (s: State) => void;
  setRandomPassword: (pw: string) => void;
  email: string;
  setEmail: (e: string) => void;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [nameOnCard, setNameOnCard] = useState('');
  const [cardCountry, setCardCountry] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  const [errorMsg, setErrorMsg] = useState<{
    error_code: string;
    message: string;
  } | null>(null);

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    const cardElement = elements.getElement(CardElement);

    if (cardElement === null) {
      return;
    }

    // Create customer and get back customer id
    const customerReq: { email: string; plan: Plan; customerId?: string } = {
      email,
      plan
    };

    if (STRIPE_CUSTOMER_ID) {
      customerReq.customerId = STRIPE_CUSTOMER_ID;
    }

    const response = await axios
      .post(`${CLOUD_FUNCTION_DOMAIN}/${FUNCTIONS.createCustomer}`, customerReq)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        setState('error');
        setLoading(false);
        console.log(err);
        return null;
      });

    if (response === null) {
      return;
    }

    const { customer, clientSecret: stripeClientSecret } = response;

    // Set temporary values to use right away
    STRIPE_CUSTOMER_ID = customer.id;
    STRIPE_PAYMENT_INTENT_SECRET = stripeClientSecret;

    let paymentMethodId = '';
    const request = {
      payment_method: {
        card: cardElement,
        billing_details: {
          email: email,
          address: {
            country: cardCountry
          },
          name: nameOnCard
        }
      }
    };

    console.log(request);

    if (plan === 'lifetime') {
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        STRIPE_PAYMENT_INTENT_SECRET,
        request
      );

      if (
        error ||
        !paymentIntent ||
        paymentIntent.status !== 'succeeded' ||
        paymentIntent.payment_method === null
      ) {
        console.log('[error]', error);
        setState('error');
        if (error && error.code && error.message) {
          setErrorMsg({ error_code: error.code, message: error.message });
        }
        setLoading(false);
        return;
      }
      paymentMethodId = paymentIntent.payment_method;
    } else {
      const { error, setupIntent } = await stripe.confirmCardSetup(
        STRIPE_PAYMENT_INTENT_SECRET,
        request
      );

      if (
        error ||
        !setupIntent ||
        setupIntent.status !== 'succeeded' ||
        setupIntent.payment_method === null
      ) {
        console.log('[error]', error);
        setState('error');
        if (error && error.code && error.message) {
          setErrorMsg({ error_code: error.code, message: error.message });
        }
        setLoading(false);
        return;
      }
      paymentMethodId = setupIntent.payment_method;
    }

    // Attach payment method to user for future charges
    const req = {
      plan,
      customerId: STRIPE_CUSTOMER_ID,
      paymentMethodId
    };

    await axios
      .post(`${CLOUD_FUNCTION_DOMAIN}/${FUNCTIONS.subscribe}`, req)
      .then(async (res) => {
        try {
          gtag('event', 'Subscribe', toAnalyticsEvent(plan));
        } catch (err) {
          console.log('Could not push analytics event');
        }

        const random = res.data.randomPassword;
        setRandomPassword(random); // Used later

        return login(email, random).then(() => {
          setLoading(false);
          setState('success');
          setDialogOpen(false);
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setState('error');
        setErrorMsg(err.response.data);
      });
  };

  return (
    <>
      <Form
        onSubmit={(e) => {
          e.preventDefault();

          if (plan === 'company') {
            window.open('https://buy.stripe.com/00gaGz33Q0p07Xa9AA');
          } else {
            setDialogOpen(true);
          }
        }}
      >
        <TextField
          name="email"
          type="email"
          id="email"
          autoComplete="email"
          label="Your email address"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          variant="filled"
          InputProps={{
            style: {
              backgroundColor: 'white',
              color: 'black',
              borderRadius: '8px'
            },
            disableUnderline: true
          }}
          margin="normal"
          fullWidth
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!stripe || loading || !email}
          style={{
            marginTop: '6px',
            width: '100%',
            padding: '14px 24px',
            fontSize: '1rem'
          }}
        >
          {loading && (
            <>
              <Loader size={24} /> Please wait, I'm kinda slow...
            </>
          )}
          {!loading && <>Join the Community</>}
        </Button>
        <Disclaimer>14-day money-back guarantee</Disclaimer>
      </Form>
      <Dialog
        open={dialogOpen}
        maxWidth="xs"
        fullWidth
        scroll="body"
        onClose={() => {
          setDialogOpen(false);
        }}
      >
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              name="ccname"
              autoComplete="cc-name"
              type="text"
              id="nameOnCard"
              label="Name on Card"
              value={nameOnCard}
              onChange={(e) => {
                setNameOnCard(e.target.value);
              }}
              variant="filled"
              InputProps={{
                style: {
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  color: 'black'
                },
                disableUnderline: true
              }}
              fullWidth
              margin="normal"
            />
            <Card
              style={{
                backgroundColor: 'white',
                border: '1px solid #767E87',
                marginBottom: '8px'
              }}
            >
              <CardElement
                options={{
                  iconStyle: 'solid',
                  style: {
                    base: {
                      iconColor: 'black',
                      color: 'black',
                      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                      fontSize: '16px',
                      fontSmoothing: 'antialiased',
                      ':-webkit-autofill': {
                        backgroundColor: 'transparent'
                      },
                      '::placeholder': { color: '#767E87' }
                    }
                  }
                }}
              />
            </Card>
            <StyledSelect
              value={cardCountry}
              onChange={(e) => {
                setCardCountry(e.target.value);
              }}
            >
              <option value="">Country or region</option>
              {COUNTRIES.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              ))}
            </StyledSelect>
            <br />
            <br />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              type="submit"
              disabled={!stripe || !nameOnCard || !cardCountry || loading}
              style={{ width: '100%', padding: '14px 24px', fontSize: '1rem' }}
            >
              {loading && (
                <>
                  <Loader size={24} /> Please wait, I'm kinda slow...
                </>
              )}
              {!loading && (
                <>
                  Join the Community &nbsp;·&nbsp;{' '}
                  {plan === 'annual' &&
                    `$${YEARLY_SUBSCRIPTION_PRICE_NORMAL} / year`}
                  {plan === 'lifetime' &&
                    `$${LIFETIME_SUBSCRIPTION_PRICE_NORMAL} for life`}
                </>
              )}
            </Button>
            {state === 'error' && errorMsg && (
              <WarningMessage style={{ marginTop: '24px' }}>
                <strong>{errorMsg.message}</strong>
                <br />
                <br />
                <small>
                  If you keep having issues, please get in touch at{' '}
                  <a href="mailto:hi@bloggingfordevs.com">
                    hi@bloggingfordevs.com
                  </a>{' '}
                  :)
                </small>
              </WarningMessage>
            )}
            <div
              style={{
                textAlign: 'center',
                margin: '1rem auto',
                opacity: 0.7
              }}
            >
              <img
                src="/images/powered-by-stripe.svg"
                style={{ width: '130px' }}
              />
            </div>
            <Disclaimer>
              By signing up, you're agreeing to the{' '}
              <Link to="/terms/">terms & privacy policy</Link> and to abide by
              our Code of Conduct. Thanks!
            </Disclaimer>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const Loader = styled(CircularProgress)`
  width: 24px !important;
  height: 24px !important;
  margin-right: ${(p) => p.theme.spacing(1)}px;

  svg {
    color: inherit;
  }
`;

const BigAvatar = styled(Avatar)`
  width: 100px;
  height: 100px;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

const ProfileGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 48px;
  max-width: 400px;
  margin: 0 auto ${(p) => p.theme.spacing(2)}px;
`;

const StepperWrapper = styled('div')`
  @media (max-width: 600px) {
    display: none;
  }
`;

const ProfileTitle = styled('div')`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 32px;
  text-align: center;
`;

const FormGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(2)}px;
  grid-row-gap: ${(p) => p.theme.spacing(2)}px;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const ProfileForm = styled('form')`
  padding: ${(p) => p.theme.spacing(4)}px ${(p) => p.theme.spacing(2)}px;
`;

const WebsiteWrapper = styled('div')`
  max-width: 400px;
  margin: 48px auto;
`;

const FinalInstructions = styled('div')`
  max-width: 400px;
  margin: 0 auto 24px;
  background: linear-gradient(130.46deg, #0050b3 4.34%, #1890ff 99.39%);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: ${(p) => p.theme.spacing(4)}px ${(p) => p.theme.spacing(4)}px;

  p {
    margin-top: 0;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: ${(p) => p.theme.spacing(4)}px;
  }
`;

const FinalButton = styled(Button)`
  background: linear-gradient(86.61deg, #f4faff 23.95%, #dcefff 97.88%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  color: ${(p) => p.theme.palette.grey[800]};
  font-size: 16px;
  font-weight: 600;
  padding: 12px 24px;
`;

const SetupProfile = ({
  email,
  randomPassword
}: {
  email: string;
  randomPassword: string;
}) => {
  const [step, setStep] = useState<number>(0);
  const { user } = useCurrentUser();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const { dialogOpen, openDialog, closeDialog } = useDialogState(false);

  const updateProfile = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    Promise.all([
      resetPassword(email, randomPassword, newPassword),
      updateName(user!.id, { firstName, lastName })
    ]).then(() => {
      setStep(1);
    });
  };

  return (
    <div>
      <StepperWrapper>
        <Stepper activeStep={step} style={{ padding: 0, margin: '0 auto' }}>
          <Step completed={step > 0}>
            <StepLabel>Create your account</StepLabel>
          </Step>
          <Step completed={step > 1}>
            <StepLabel>Add your blog (Optional)</StepLabel>
          </Step>
          <Step completed={step > 2}>
            <StepLabel>Go to community</StepLabel>
          </Step>
        </Stepper>
      </StepperWrapper>
      {step === 2 && (
        <div>
          <Title style={{ marginTop: '48px' }}>
            Last step ahead, {user?.user.firstName}!
          </Title>
          <FinalInstructions>
            <p>You’re now logged into the website.</p>
            <p>
              In a second, you'll use the email and password you JUST created to
              access the community.
            </p>
            <p>
              Now, <HighlightWhite>click the button below</HighlightWhite> to
              head to the community, finish your community profile, and
              introduce yourself 👋
            </p>
            <FinalButton href="https://community.bloggingfordevs.com">
              Go to the Community →
            </FinalButton>
          </FinalInstructions>
        </div>
      )}
      {step === 1 && user && (
        <WebsiteWrapper>
          <YourBlogs user={user} />
          <Button
            onClick={() => {
              setStep(2);
            }}
            variant="contained"
            color="primary"
            fullWidth
            style={{
              margin: '12px auto 0',
              padding: '12px 24px',
              fontSize: '16px',
              maxWidth: '400px',
              fontWeight: 700
            }}
            size="large"
          >
            Continue
          </Button>
        </WebsiteWrapper>
      )}
      {step === 0 && (
        <ProfileForm onSubmit={updateProfile} autoComplete="off">
          <ProfileTitle>Welcome! Let's set up your account 🎉</ProfileTitle>
          <ProfileGrid>
            <div style={{ textAlign: 'center', marginBottom: '36px' }}>
              <ButtonBase onClick={openDialog}>
                {user && <BigAvatar src={user.user.avatarUrl} />}
              </ButtonBase>
              <Typography variant="body1" color="textSecondary" component="div">
                <strong>
                  Click the user icon above to set your profile picture
                </strong>
              </Typography>
            </div>
            <FormGrid>
              <TextField
                type="text"
                name="firstName"
                id="firstName"
                label="First name"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                variant="filled"
              />
              <TextField
                type="text"
                name="surname"
                id="surname"
                label="Last name"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                variant="outlined"
              />
            </FormGrid>
            <TextField
              type="password"
              name="new-password"
              id="new-password"
              label="Choose a password"
              fullWidth
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
              variant="outlined"
            />
          </ProfileGrid>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!firstName || !newPassword}
              fullWidth
              style={{
                margin: '12px auto 0',
                padding: '12px 24px',
                fontSize: '16px',
                maxWidth: '400px',
                fontWeight: 700
              }}
              size="large"
            >
              Save and continue
            </Button>
          </div>
        </ProfileForm>
      )}
      <ImageUploadDialog
        title={'Upload your profile picture'}
        src={user ? user.user.avatarUrl : ''}
        open={dialogOpen}
        onCancel={closeDialog}
        onUpload={async (blob) => {
          if (!user) {
            return;
          }
          try {
            const filePath = `avatars/${shortid()}.jpg`;
            await storage().ref(filePath).put(blob);
            const avatarUrl = `https://storage.googleapis.com/bloggingfordevs.appspot.com/${filePath}`;
            await updateAvatarUrl(user.id, avatarUrl);
          } catch (err) {
            console.log(err);
          }
        }}
      >
        {({ onClose }) => (
          <ButtonWithPromise
            color="primary"
            pending="Updating..."
            onClick={async () => {
              if (!user) {
                return;
              }
              await updateAvatarUrl(
                user.id,
                `https://www.gravatar.com/avatar/${md5(user.user.email)}`
              );
              onClose();
            }}
          >
            Use Gravatar avatar
          </ButtonWithPromise>
        )}
      </ImageUploadDialog>
    </div>
  );
};

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const Disclaimer = styled('div')`
  color: ${(p) => p.theme.palette.grey[300]};
  font-size: 12px;
  text-align: center;
  max-width: 350px;
  margin: 24px auto 0;

  a {
    color: ${(p) => p.theme.palette.primary.main};
  }
`;

const PlanLabel = styled('div')`
  position: absolute;
  bottom: -10px;
  border: 1px solid ${(p) => p.theme.palette.primary.main};
  background-color: #191c20;
  color: ${(p) => p.theme.palette.primary.main};
  font-size: 12px;
  padding: 1px 8px 2px 8px;
  border-radius: 12px;
`;

const PlanButton = styled<typeof ButtonBase, { selected: boolean }>(ButtonBase)`
  position: relative;
  background-color: #191c20;
  border-radius: 8px;
  font-size: 18px;
  font-family: inherit;
  width: 100%;
  padding: 22px 24px;
  margin-bottom: 16px;
  color: ${(p) =>
    p.selected ? p.theme.palette.primary.main : p.theme.palette.grey[300]};
  font-weight: 600;

  span {
    color: ${(p) => p.theme.palette.grey[400]};
    text-decoration: line-through;
    display: inline-block;
    margin-right: 18px;
    font-weight: 400;
  }

  border: 1px solid
    ${(p) => (p.selected ? p.theme.palette.primary.main : 'transparent')};
`;

const Title = styled('div')`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  max-width: 400px;
  margin: 0 auto 32px;
`;

const Wrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 320px;
  grid-column-gap: 24px;
  align-items: center;

  & > div:first-of-type {
    border-right: 1px solid black;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-row-gap: 32px;

    & > div:first-of-type {
      border: none;
    }
  }
`;

const Benefits = styled('ul')`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const BenefitsLi = styled('li')`
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-column-gap: 18px;
  align-items: center;
  padding-right: 24px;

  @media (max-width: 800px) {
    padding: 0;
  }

  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
`;

const BenefitsIcon = styled('div')`
  display: flex;
  align-items: center;
  height: 50px;
  width: 50px;
  justify-content: center;
  border-radius: 100%;

  &.bfd-trending {
    color: #237804;
    background-color: #bae637;
  }

  &.bfd-feedback {
    color: #0050b3;
    background-color: #40a9ff;
  }

  &.bfd-events {
    color: #a8071a;
    background-color: #ff4d4f;
  }

  &.bfd-guides {
    color: #391085;
    background-color: #9254de;
  }

  &.bfd-discount {
    color: #ad6800;
    background-color: #faad14;
  }
`;

const BenefitsText = styled('div')`
  color: ${(p) => p.theme.palette.grey[400]};
  display: flex;
  align-items: center;

  p {
    padding: 0;
    margin: 0;
  }

  strong {
    color: ${(p) => p.theme.palette.grey[100]};
  }
`;

const MembershipPeriod = styled('div')`
  text-align: center;
  color: ${(p) => p.theme.palette.grey[300]};
  margin-top: 16px;
  margin-bottom: 8px;
  font-weight: bold;
`;

export const Subscription = ({
  onChangeState,
  onSuccess
}: {
  onChangeState: (newValue: 'signup' | 'login') => any;
  onSuccess?: () => any;
}) => {
  const [plan, setPlan] = useState<Plan>('lifetime');
  const [randomPassword, setRandomPassword] = useState('');
  const [state, setState] = useState<'initial' | 'success' | 'error'>(
    INITIAL_STATE
  );

  const setSelectedPlan = (p: Plan) => {
    setPlan(p);
    STRIPE_PAYMENT_INTENT_SECRET = '';
  };

  const urlParam = (() => {
    if (typeof window === 'undefined') {
      return INITIAL_EMAIL;
    }
    const params = new URLSearchParams(window.location.search);
    return params.get('email') || INITIAL_EMAIL;
  })();

  const [email, setEmail] = useState(urlParam);

  if (state === 'success') {
    return <SetupProfile email={email} randomPassword={randomPassword} />;
  }

  return (
    <Wrapper>
      <div>
        <Title>
          Become part of the
          <br />
          Blogging for Devs Community!
          <AvatarGroup
            max={10}
            style={{ justifyContent: 'center', marginTop: '12px' }}
          >
            <Avatar src="/images/rey-small.jpg" />
            <Avatar src="/images/princiya-small.jpg" />
            <Avatar src="/images/alyssa-small.jpg" />
            <Avatar src="/images/joe-small.png" />
            <Avatar src="/images/rachel-small.jpg" />
            <Avatar src="/images/chris-small.jpg" />
          </AvatarGroup>
        </Title>
        <Benefits>
          <BenefitsLi>
            <BenefitsIcon className="bfd-trending">
              <TrendingUp size={24} />
            </BenefitsIcon>
            <BenefitsText>
              <p>
                <strong>Private community of 350+ developers</strong> you can
                learn from and collaborate with.
              </p>
            </BenefitsText>
          </BenefitsLi>
          <BenefitsLi>
            <BenefitsIcon className="bfd-feedback">
              <MessageCircle size={24} />
            </BenefitsIcon>
            <BenefitsText>
              <p>
                <strong>Get feedback on your articles</strong>, blog,
                newsletter, content, or distribution plan.
              </p>
            </BenefitsText>
          </BenefitsLi>
          <BenefitsLi>
            <BenefitsIcon className="bfd-guides">
              <Key size={24} />
            </BenefitsIcon>
            <BenefitsText>
              <p>
                <strong>Join live AMAs &amp; cohort events</strong> with experts
                and creators in tech you already follow.
              </p>
            </BenefitsText>
          </BenefitsLi>
          <BenefitsLi>
            <BenefitsIcon className="bfd-events">
              <Video size={24} />
            </BenefitsIcon>
            <BenefitsText>
              <p>
                <strong>Access to the Video Vault</strong> containing video
                screencasts and past events (all captioned).
              </p>
            </BenefitsText>
          </BenefitsLi>
          <BenefitsLi>
            <BenefitsIcon className="bfd-discount">
              <Star size={24} />
            </BenefitsIcon>
            <BenefitsText>
              <p>
                <strong>Course and Workshop access</strong> worth over $250 when
                accessed separately.
              </p>
            </BenefitsText>
          </BenefitsLi>
        </Benefits>
      </div>
      <div>
        <MembershipPeriod
          style={{
            color: plan !== 'company' ? '#A4ACB6' : '#40a9ff'
          }}
        >
          Join as a company
        </MembershipPeriod>
        <PlanButton
          style={{ marginBottom: '20px' }}
          selected={plan === 'company'}
          onClick={() => {
            setSelectedPlan('company');
          }}
        >
          <PlanLabel
            style={{
              borderColor: plan !== 'company' ? '#22262b' : '',
              color: plan !== 'company' ? '#E3E9F0' : ''
            }}
          >
            Lifetime access for up to 3 devs
          </PlanLabel>
          ${COMPANY_SUBSCRIPTION_PRICE_NORMAL} once
        </PlanButton>
        <MembershipPeriod
          style={{
            color: plan !== 'lifetime' ? '#A4ACB6' : '#40a9ff'
          }}
        >
          Join for life
        </MembershipPeriod>
        <PlanButton
          style={{ marginBottom: '20px' }}
          selected={plan === 'lifetime'}
          onClick={() => {
            setSelectedPlan('lifetime');
          }}
        >
          <PlanLabel
            style={{
              borderColor: plan !== 'lifetime' ? '#22262b' : '',
              color: plan !== 'lifetime' ? '#E3E9F0' : ''
            }}
          >
            All current and future content, forever
          </PlanLabel>
          ${LIFETIME_SUBSCRIPTION_PRICE_NORMAL} once
        </PlanButton>
        <MembershipPeriod
          style={{
            color: plan !== 'annual' ? '#A4ACB6' : '#40a9ff'
          }}
        >
          Join yearly
        </MembershipPeriod>
        <PlanButton
          style={{ marginBottom: '20px' }}
          selected={plan === 'annual'}
          onClick={() => {
            setSelectedPlan('annual');
          }}
        >
          <PlanLabel
            style={{
              borderColor: plan !== 'annual' ? '#22262b' : '',
              color: plan !== 'annual' ? '#E3E9F0' : ''
            }}
          >
            Paid annually
          </PlanLabel>
          ${YEARLY_SUBSCRIPTION_PRICE_NORMAL} / year
        </PlanButton>
        <Elements stripe={stripePromise}>
          <CheckoutForm
            plan={plan}
            state={state}
            setState={setState}
            setRandomPassword={(pw: string) => {
              setRandomPassword(pw);
            }}
            email={email}
            setEmail={(e) => {
              setEmail(e);
            }}
          />
        </Elements>
      </div>
    </Wrapper>
  );
};
