import React from 'react';
import { Link } from 'gatsby';
import styled from '../styled';
import { useCurrentUser } from '../services/currentUser';

export const Branding = styled(Link)`
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  display: block;
  text-align: center;
  color: ${(p) => p.theme.palette.grey['400']};
  letter-spacing: 2px;
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
  transition: opacity 0.2s ease-in;
`;

export const BrandingA = styled('a')`
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  display: block;
  text-align: center;
  color: ${(p) => p.theme.palette.grey['400']};
  letter-spacing: 2px;
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
  transition: opacity 0.2s ease-in;
`;

export const HidableBranding: React.FC<{ to: string; style?: any }> = ({
  to,
  style = {},
  children
}) => {
  const { isAuthenticated, loadingUser } = useCurrentUser();
  return (
    <Branding
      to={to}
      style={{ ...style, opacity: loadingUser || isAuthenticated ? 0 : 1 }}
    >
      {children}
    </Branding>
  );
};
