import firebase from 'firebase/app';
import shortid from 'shortid';
import { FS } from '../constants';
import { Doc, generateToDocFn } from '../types/Document';
import { Website } from '../types/Website';
import {
  CollectionListener,
  createCollectionListenerStore,
  createSingleCollectionListenerStore,
  useCollectionListener
} from './collectionListener';
import { setDoc, store } from './db';

export const websitesCollection = () => store().collection(FS.websites);

const websitesByUserStore = createCollectionListenerStore(
  (userId) =>
    new CollectionListener(
      websitesCollection().where('userIds', 'array-contains', userId),
      toWebsiteDoc
    )
);

export const toWebsiteDoc = generateToDocFn<Website>((d) => {
  d.name = d.name || '';
  d.description = d.description || '';
  d.rssUrl = d.rssUrl || '';
  d.faviconUrl = d.faviconUrl || '';
  d.etag = d.etag || '';
  return d;
});

const getWebsiteListener = createSingleCollectionListenerStore(
  () => new CollectionListener(websitesCollection(), toWebsiteDoc)
);

export const updateWebsite = (websiteId: string, website: Website) => {
  return websitesCollection().doc(websiteId).set(website);
};

export const removeWebsite = (websiteId: string) => {
  return websitesCollection().doc(websiteId).delete();
};

export const createWebsite = (website: Website, userId: string) => {
  const doc: Doc<Website> = {
    id: shortid(),
    collection: FS.websites,
    data: {
      ...website,
      userIds: [userId],
      createdAt: firebase.firestore.Timestamp.now()
    }
  };
  return setDoc(doc);
};

export const useWebsitesByUser = (userId: string) => {
  return useCollectionListener(websitesByUserStore(userId));
};

export const useWebsites = () => {
  return useCollectionListener(getWebsiteListener());
};

export const toPrettyWebsiteUrl = (url: string) => {
  const http = 'http://';
  const https = 'https://';
  const www = 'www.';

  let withoutProtocol = '';

  if (url.startsWith(http)) {
    withoutProtocol = url.substr(http.length, url.length - 1);
  } else if (url.startsWith(https)) {
    withoutProtocol = url.substr(https.length, url.length - 1);
  } else {
    withoutProtocol = url;
  }

  return withoutProtocol.startsWith(www)
    ? withoutProtocol.substr(www.length, withoutProtocol.length - 1)
    : withoutProtocol;
};
