import { Button } from '@material-ui/core';
import styled from '../styled';

export const MiniButton = styled(Button)`
  height: 18px;
  font-size: 0.7rem;
  font-weight: 600;
  padding: 4px 10px;
  margin-bottom: 2px;
  background-color: ${(p) => p.theme.palette.grey[900]};
  color: ${(p) => p.theme.palette.grey[200]};

  &:hover {
    background-color: ${(p) => p.theme.palette.common.black};
  }

  .MuiButton-label {
    text-transform: uppercase;
  }
`;
