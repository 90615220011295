import { DialogActions } from '@material-ui/core';
import React from 'react';
import styled, { css } from '../styled';

const Left = styled('div')`
  display: flex;
  align-items: center;
  > :not(:last-child) {
    margin-right: ${(p) => p.theme.spacing()}px;
  }
`;

const Right = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > :not(:first-child) {
    margin-left: ${(p) => p.theme.spacing()}px;
  }
`;

export const DialogActionsWithLeftSlot: React.FC<{
  left?: React.ReactNode;
}> = ({ left, children }) => {
  return (
    <DialogActions
      classes={{ spacing: css(() => ({ justifyContent: 'space-between' })) }}
    >
      <Left>{left}</Left>
      <Right>{children}</Right>
    </DialogActions>
  );
};
