import { useCallback, useState } from 'react';

export const useDialogState = (initialState = false) => {
  const [dialogOpen, setDialogOpen] = useState(initialState);
  const openDialog = useCallback(() => setDialogOpen(true), []);
  const closeDialog = useCallback(() => setDialogOpen(false), []);
  return {
    dialogOpen,
    setDialogOpen,
    openDialog,
    closeDialog
  };
};
