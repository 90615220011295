import axios from 'axios';
import { functions } from 'firebase/app';
import { CLOUD_FUNCTION_DOMAIN } from '../constants';
import { usePromise } from '../hooks/usePromise';

export const post = async <T extends object = any>(
  fnName: string,
  data: object,
  headers: { [key: string]: string } = {}
): Promise<T> => {
  const url = `${CLOUD_FUNCTION_DOMAIN}/${fnName}`;
  return axios
    .post<T>(url, { data, headers })
    .then((r) => r.data);
};

export const callFirebaseFunction = <T, P = object>(
  fnName: string,
  data?: P,
  timeout: number = 550000 // higher than what's a CF is allowed to run
) => {
  return functions()
    .httpsCallable(fnName, { timeout })(data)
    .then((res) => res.data as T);
};

const cache: { [cacheKey: string]: { expiresAt: number; result: any } } = {};
const toCacheKey = (fnName: string, data?: object) =>
  `${fnName}-${JSON.stringify(data)}`;

export const useFirebaseFunction = <T>(
  fnName: string,
  data?: object,
  listeners: any[] = [],
  cacheExpiryTime: number = 0
) => {
  return usePromise(() => {
    if (cacheExpiryTime) {
      const now = Date.now();
      const cacheKey = toCacheKey(fnName, data || {});
      const cacheItem = cache[cacheKey];
      if (cacheItem && now < cacheItem.expiresAt) {
        return Promise.resolve(cacheItem.result as T);
      }
      return callFirebaseFunction<T>(fnName, data).then((result) => {
        cache[cacheKey] = { expiresAt: now + cacheExpiryTime, result };
        return result;
      });
    } else {
      return callFirebaseFunction<T>(fnName, data);
    }
  }, listeners);
};
