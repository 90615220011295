import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import React, { useState } from 'react';
import { readFileAsDataURL } from '../services/file';
import { cropImage } from '../services/image';
import styled from '../styled';
import { ButtonWithPromise } from './ButtonWithPromise';
import { Dropzone } from './Dropzone';
import { ImageCrop } from './ImageCrop';

export const CenteredBody = styled('div')((p) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const ImageUploadDialog = ({
  title,
  open,
  onCancel,
  src,
  onUpload,
  children
}: {
  title: string;
  open: boolean;
  onCancel: () => void;
  src: string;
  onUpload: (nextImage: Blob) => Promise<any>;
  children: (p: { onClose: () => void }) => React.ReactNode;
}) => {
  const [img, setImg] = useState<string | null>(null);
  const [imgRef, setImgRef] = useState<HTMLImageElement | null>(null);
  const [crop, setCrop] = useState<ReactCrop.Crop>({ aspect: 1 });
  const close = () => {
    onCancel();
    setImg(null);
    setImgRef(null);
    setCrop({ aspect: 1 });
  };
  return (
    <Dialog open={open} onClose={close} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <CenteredBody>
          {img ? (
            <ImageCrop
              src={img}
              crop={crop}
              onChange={setCrop}
              onImageLoaded={(image) => {
                setImgRef(image);
                image.width > image.height
                  ? setCrop({
                      aspect: 1,
                      height: image.height,
                      x: Math.round((image.width - image.height) / 2)
                    })
                  : setCrop({
                      aspect: 1,
                      width: image.width,
                      y: Math.round((image.height - image.width) / 2)
                    });
                return false; // Return false when setting crop state in here.
              }}
            />
          ) : (
            <Dropzone
              label="Drag and drop your image here, or click to select it"
              onDrop={async (f) => {
                const nextImg = await readFileAsDataURL(f[0]);
                setImg(nextImg);
              }}
            />
          )}
        </CenteredBody>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        {children && children({ onClose: close })}
        <ButtonWithPromise
          variant="contained"
          color="primary"
          onClick={async () => {
            if (!imgRef) {
              return;
            }
            return cropImage(imgRef, crop).then(onUpload).then(close);
          }}
          pending="Uploading..."
        >
          Upload
        </ButtonWithPromise>
      </DialogActions>
    </Dialog>
  );
};
