import { ParseConfig } from 'papaparse';

export const readFileAsDataURL = (file: File): Promise<string> => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = function (e) {
      resolve(this.result as string);
    };
    reader.onerror = function (e) {
      reject(this.error);
    };
    reader.readAsDataURL(file);
  });
};

export const readFileAsText = (file: File): Promise<string> => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = function (e) {
      resolve(this.result as string);
    };
    reader.onerror = function (e) {
      reject(this.error);
    };
    reader.readAsText(file);
  });
};

export const readCsvRows = async (
  file: File,
  options: ParseConfig
): Promise<any[][]> => {
  const papa = await import('papaparse');
  return new Promise((resolve, reject) => {
    papa.parse(file, {
      skipEmptyLines: true,
      error: reject,
      complete: (res) => resolve(res.data),
      ...options
    });
  });
};
