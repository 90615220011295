import React from 'react';
import { Button, IconButton, Avatar } from '@material-ui/core';
import { Rss, Edit } from 'react-feather';
import styled from '../styled';
import { Doc } from '../types/Document';
import { Website } from '../types/Website';

const RssWarning = styled(Button)`
  color: #ff7875;
  margin-top: ${(p) => p.theme.spacing(1)}px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  padding: 0;

  svg {
    margin-right: ${(p) => p.theme.spacing(1)}px;
  }
`;

const WebsiteWrapper = styled('div')`
  display: grid;
  grid-template-columns: 48px 1fr 12px;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing(1)}px;
`;

const MetaButton = styled(IconButton)`
  color: ${(p) => p.theme.palette.primary.main};
`;

const WebsiteMeta = styled('div')`
  margin-left: ${(p) => p.theme.spacing(2)}px;
`;

const WebsiteName = styled('div')`
  font-weight: 700;
  display: flex;
  align-items: center;
`;

const WebsiteDescription = styled('div')`
  color: ${(p) => p.theme.palette.grey['400']};
`;

export const WebsitePreview = ({
  blog,
  onClick
}: {
  blog: Doc<Website>;
  onClick: (w: Doc<Website>) => void;
}) => {
  const initial = blog.data.url.substr(
    'https://'.length,
    blog.data.url.length - 1
  );

  return (
    <WebsiteWrapper>
      <Avatar src={blog.data.faviconUrl} alt={blog.data.name}>
        {initial.substr(0, 1).toUpperCase()}
      </Avatar>
      <WebsiteMeta>
        <WebsiteName>
          {blog.data.name || blog.data.url}{' '}
          <MetaButton
            onClick={(e) => {
              e.preventDefault();
              onClick(blog);
            }}
          >
            <Edit size={16} />
          </MetaButton>
        </WebsiteName>
        <WebsiteDescription>
          {blog.data.description || <em>Add a description</em>}
        </WebsiteDescription>
        {!blog.data.rssUrl && (
          <RssWarning
            onClick={(e) => {
              e.preventDefault();
              onClick(blog);
            }}
          >
            <Rss size={12} /> RSS Feed Missing
          </RssWarning>
        )}
      </WebsiteMeta>
    </WebsiteWrapper>
  );
};
