import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useState } from 'react';
import { Doc } from '../types/Document';
import { Website } from '../types/Website';
import { ButtonWithPromise } from './ButtonWithPromise';
import { DialogActionsWithLeftSlot } from './DialogActionsWithLeftSlot';

export const WebsiteDialog = ({
  website,
  mode,
  open,
  onClose,
  onSubmit,
  onRemove
}: {
  website: Doc<Website>;
  mode: 'edit' | 'create';
  open: boolean;
  onClose: () => void;
  onSubmit: (website: Website) => Promise<any>;
  onRemove?: () => Promise<any>;
}) => {
  const [loading, setLoading] = useState(false);
  const [websiteValue, setWebsiteValue] = useState(website.data);
  const formDisabled =
    !websiteValue.name.length ||
    !websiteValue.description.length ||
    !websiteValue.url.length;

  const submitForm = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);

    return onSubmit(websiteValue).catch(() => {
      setLoading(false);
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {mode === 'create' ? 'Add your blog' : 'Edit this blog'}
      </DialogTitle>
      <form autoComplete="off" onSubmit={submitForm}>
        <DialogContent>
          {mode === 'create' && (
            <Typography variant="body1" component="p">
              Adding your blog lets us include it in the Members RSS feed and
              link you throughout the website.
            </Typography>
          )}
          <TextField
            name="blog-name"
            id="blog-name"
            value={websiteValue.name}
            onChange={(e) =>
              setWebsiteValue({ ...websiteValue, name: e.target.value })
            }
            variant="outlined"
            label="Your blog's name"
            required
            fullWidth
            margin="normal"
            helperText="Example: See Jane Code"
          />
          <TextField
            name="description"
            id="description"
            variant="outlined"
            value={websiteValue.description}
            onChange={(e) =>
              setWebsiteValue({ ...websiteValue, description: e.target.value })
            }
            label="A short description of your blog"
            required
            fullWidth
            margin="normal"
            helperText="Example: Tech tutorials and news on Golang"
          />
          <TextField
            name="url"
            id="url"
            variant="outlined"
            value={websiteValue.url}
            onChange={(e) =>
              setWebsiteValue({ ...websiteValue, url: e.target.value })
            }
            required
            label="Link to your blog"
            fullWidth
            margin="normal"
            helperText="Example: https://seejanecode.com/blog/"
          />
          <TextField
            name="rssUrl"
            id="rssUrl"
            type="url"
            value={websiteValue.rssUrl}
            onChange={(e) =>
              setWebsiteValue({ ...websiteValue, rssUrl: e.target.value })
            }
            variant="outlined"
            label="URL of your RSS feed"
            fullWidth
            margin="normal"
            helperText="Example: https://seejanecode.com/rss.xml"
          />
          <TextField
            name="rssUrl"
            id="rssUrl"
            type="url"
            value={websiteValue.faviconUrl}
            onChange={(e) =>
              setWebsiteValue({ ...websiteValue, faviconUrl: e.target.value })
            }
            variant="outlined"
            label="URL of favicon"
            fullWidth
            margin="normal"
            helperText="Example: https://seejanecode.com/images/favicon.png"
          />
        </DialogContent>
        <DialogActionsWithLeftSlot
          left={
            mode === 'edit' &&
            onRemove && (
              <ButtonWithPromise
                color="secondary"
                variant="outlined"
                onClick={onRemove}
                pending="Removing..."
              >
                Remove
              </ButtonWithPromise>
            )
          }
        >
          <Button
            variant="text"
            color="default"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={formDisabled || loading}
          >
            {mode === 'create' ? 'Add blog' : 'Save changes'}
          </Button>
        </DialogActionsWithLeftSlot>
      </form>
    </Dialog>
  );
};
