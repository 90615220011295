import React, { useEffect, useState } from 'react';

export const onlyOnClientSide = <T extends object>(
  Component: React.ComponentType<T>,
  LoadingComponent?: React.ComponentType<T>
) => {
  return (p: T) => (
    <ClientSideOnly
      fallback={LoadingComponent ? <LoadingComponent {...p} /> : undefined}
    >
      <Component {...p} />
    </ClientSideOnly>
  );
};

export const ClientSideOnly: React.FC<{ fallback?: React.ReactNode }> = ({
  children,
  fallback,
  ...rest
}) => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return <div {...rest}>{fallback}</div>;
  }

  return <div {...rest}>{children}</div>;
};
