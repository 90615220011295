import { Typography } from '@material-ui/core';
import React from 'react';
import { Footer } from '../components/Footer';
import { Header, MOBILE_NAV_HEIGHT, NAV_HEIGHT } from '../components/Header';
import { Subheading } from '../components/Subheading';
import styled from '../styled';
import withRoot from '../withRoot';

export const Wrapper = styled('div')`
  max-width: 800px;
  padding: 12px;
  margin: ${NAV_HEIGHT + 24}px auto ${(p) => p.theme.spacing(6)}px;

  @media (max-width: 800px) {
    margin: ${MOBILE_NAV_HEIGHT + 24}px auto;
  }
`;

const Layout = ({
  children,
  styles
}: {
  children: React.ReactNode;
  styles: React.CSSProperties;
}) => {
  return (
    <>
      <Header />
      <div style={styles}>{children}</div>
      <Footer />
    </>
  );
};

export const LayoutWithoutHeader = withRoot(
  ({ children }: { children: React.ReactNode }) => (
    <>
      <div>{children}</div>
      <Footer />
    </>
  )
);

export default withRoot(Layout);

export const Grid = styled('div')`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(6)}px;
  grid-row-gap: ${(p) => p.theme.spacing(3)}px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

export const GridSection = styled('div')((p) => ({
  marginBottom: p.theme.spacing(4)
}));

export const GridSectionHeading = Subheading;

export const GridSectionBody = styled('div')((p) => ({
  marginTop: p.theme.spacing(2)
}));

export const MainHeader = ({
  title,
  subtitle
}: {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
}) => {
  return (
    <Typography
      variant="h4"
      component="h1"
      gutterBottom
      style={{ textAlign: 'center', marginBottom: '5rem' }}
    >
      {title}
      {subtitle && (
        <Typography component="div" variant="body1" style={{ marginTop: 12 }}>
          {subtitle}
        </Typography>
      )}
    </Typography>
  );
};
