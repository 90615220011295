export const cropImage = (image: HTMLImageElement, crop: ReactCrop.Crop) => {
  const { width = 0, height = 0, x = 0, y = 0 } = crop;
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('CANNOT_CREATE_CANVAS');
  }

  ctx.drawImage(
    image,
    x * scaleX,
    y * scaleY,
    width * scaleX,
    height * scaleY,
    0,
    0,
    width,
    height
  );

  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          reject('CANNOT_CREATE_BLOB');
          return;
        }
        resolve(blob);
      },
      'image/jpeg',
      1
    );
  });
};

export const cropImageFromDataURL = (dataURL: string, crop: ReactCrop.Crop) => {
  return new Promise<Blob>((resolve, reject) => {
    const image = document.createElement('img');
    image.src = dataURL;
    image.onload = () => {
      cropImage(image, crop).then(resolve, reject);
    };
  });
};
