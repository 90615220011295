import { Typography } from '@material-ui/core';
import React from 'react';
import {
  GridSection,
  GridSectionBody,
  GridSectionHeading
} from '../layouts/Layout';
import { UnderlinedA } from './UnderlinedA';

export const QuestionsGridSection = () => {
  return (
    <GridSection>
      <GridSectionHeading>Questions?</GridSectionHeading>
      <GridSectionBody>
        <Typography variant="body1" component="p">
          Shoot an email to{' '}
          <UnderlinedA href="mailto:monica@bloggingfordevs.com">
            monica@bloggingfordevs.com
          </UnderlinedA>
        </Typography>
      </GridSectionBody>
    </GridSection>
  );
};
