import {
  Avatar,
  Button,
  ButtonBase,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import firebase from 'firebase/app';
import md5 from 'md5';
import React, { useMemo, useState } from 'react';
import { ExternalLink } from 'react-feather';
import shortid from 'shortid';
import { ButtonWithPromise } from '../components/ButtonWithPromise';
import { onlyOnClientSide } from '../components/ClientSidePage';
import { ImageUploadDialog } from '../components/ImageUpload';
import { MiniButton } from '../components/MiniButton';
import { QuestionsGridSection } from '../components/QuestionsGridSection';
import { SEO } from '../components/SEO';
import { Subheading } from '../components/Subheading';
import { UnderlinedA } from '../components/UnderlinedA';
import { WebsiteDialog } from '../components/WebsiteDialog';
import { WebsitePreview } from '../components/WebsitePreview';
import { useDialogState } from '../hooks/useDialogState';
import { useSnackbar } from '../hooks/useSnackbar';
import Layout, {
  Grid,
  GridSection,
  GridSectionBody,
  GridSectionHeading,
  Wrapper
} from '../layouts/Layout';
import { logout } from '../services/auth';
import { getBillingPortalUrl } from '../services/billing';
import { useCurrentUser } from '../services/currentUser';
import { storage } from '../services/db';
import { updateAvatarUrl, updateProfile } from '../services/users';
import { toUserName } from '../services/usersPublic';
import {
  createWebsite,
  removeWebsite,
  updateWebsite,
  useWebsitesByUser
} from '../services/website';
import styled from '../styled';
import { Doc } from '../types/Document';
import { CurrentUser } from '../types/User';
import { Website } from '../types/Website';

const AdminButton = styled(Button)`
  color: ${(p) => p.theme.palette.grey['200']};
  font-size: ${(p) => p.theme.typography.body1.fontSize};
  min-width: auto;
  padding: 0;
  margin: 12px 0;
  display: block;
`;

const ProfileWrapper = styled(Paper)`
  padding: ${(p) => p.theme.spacing(4)}px ${(p) => p.theme.spacing(4)}px;
  display: flex;
  align-items: center;
`;

const Admin = styled('div')`
  a {
    border-bottom: 1px solid ${(p) => p.theme.palette.grey['200']};
  }
`;

const BigAvatar = styled(Avatar)`
  width: 100px;
  height: 100px;
  margin-right: ${(p) => p.theme.spacing(2)}px;
`;

const FormRowDouble = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(1)}px;
  grid-row-gap: ${(p) => p.theme.spacing(2)}px;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

const FormRowSingle = styled('div')`
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

const ProfileForm = styled('form')`
  margin-top: ${(p) => p.theme.spacing(8)}px;
`;

const FormSection = styled('div')`
  margin: 2rem 0;
`;

const Profile = ({ user }: { user: CurrentUser }) => {
  const [userProfile, setUserProfile] = useState(user.user);
  const [loading, setLoading] = useState(false);
  const { dialogOpen, openDialog, closeDialog } = useDialogState(false);
  const { enqueueSnackbar } = useSnackbar();
  const formDisabled =
    !userProfile.firstName || !userProfile.lastName || loading;

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);

    updateProfile(user.id, userProfile)
      .then(() => {
        enqueueSnackbar('Your profile was updated!', { variant: 'success' });
        setLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
        setLoading(false);
      });
  };

  return (
    <div>
      <ProfileWrapper>
        <ButtonBase onClick={openDialog}>
          <BigAvatar src={user.user.avatarUrl} />
        </ButtonBase>
        <div>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            style={{ fontWeight: 600 }}
          >
            Your profile
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            Click on your avatar to change the photo
          </Typography>
        </div>
      </ProfileWrapper>
      <ProfileForm onSubmit={onSubmit}>
        <FormRowDouble>
          <TextField
            name="firstName"
            value={userProfile.firstName}
            label="First name"
            variant="outlined"
            onChange={(e) => {
              setUserProfile({ ...userProfile, firstName: e.target.value });
            }}
            required
          />
          <TextField
            name="lastName"
            value={userProfile.lastName}
            label="Last name"
            variant="outlined"
            onChange={(e) => {
              setUserProfile({ ...userProfile, lastName: e.target.value });
            }}
            required
          />
        </FormRowDouble>
        <FormRowSingle>
          <TextField
            disabled
            name="email"
            label="Email address"
            variant="outlined"
            helperText={
              <>
                Need to change your email? Get in touch at{' '}
                <a href="mailto:hi@bloggingfordevs.com">
                  hi@bloggingfordevs.com
                </a>
              </>
            }
            value={userProfile.email}
          />
        </FormRowSingle>
        <FormSection>
          <Subheading>Community Profile</Subheading>
          <br />
          <FormRowSingle>
            <Typography variant="body1" component="p" paragraph>
              Edit your private community profile directly{' '}
              <UnderlinedA
                href="https://community.bloggingfordevs.com/settings/profile"
                target="_blank"
              >
                in the community <ExternalLink size={12} />
              </UnderlinedA>
              .
            </Typography>
          </FormRowSingle>
        </FormSection>
        <FormSection>
          <Subheading>Public Profile</Subheading>
          <br />
          <Typography variant="body1" component="p" paragraph>
            The information shown on public parts of the website you participate
            in (like comments on the blog). You can adjust the visibility of
            this information below in the Privacy settings.
          </Typography>
          <br />
          <FormRowDouble>
            <TextField
              name="headline"
              label="Headline"
              variant="outlined"
              helperText="Example: Writer, SeeJaneCode.com"
              value={userProfile.headline}
              onChange={(e) => {
                setUserProfile({ ...userProfile, headline: e.target.value });
              }}
            />
          </FormRowDouble>
          <FormRowSingle>
            <TextField
              name="bio"
              multiline
              label="Bio"
              rows={3}
              variant="outlined"
              inputProps={{
                maxLength: 250
              }}
              helperText={
                <>
                  Example: Hey everyone! I’m a JavaScript developer and aspiring
                  podcast host. Besides coding, I love knitting, mechanical
                  keyboards, and piña coladas.
                  <br />
                  <br />
                  Max length: 250 characters
                </>
              }
              value={userProfile.bio}
              onChange={(e) => {
                setUserProfile({ ...userProfile, bio: e.target.value });
              }}
            />
          </FormRowSingle>
          <FormRowDouble>
            <TextField
              name="location"
              label="Location"
              variant="outlined"
              helperText="Example: Berlin, Germany"
              value={userProfile.location}
              onChange={(e) => {
                setUserProfile({ ...userProfile, location: e.target.value });
              }}
            />
          </FormRowDouble>
          <FormRowDouble>
            <TextField
              name="twitterUrl"
              label="Twitter URL"
              type="url"
              variant="outlined"
              helperText="Example: https://twitter.com/bloggingfordevs"
              value={userProfile.twitterUrl}
              onChange={(e) => {
                setUserProfile({ ...userProfile, twitterUrl: e.target.value });
              }}
            />
          </FormRowDouble>
        </FormSection>
        <FormSection>
          <Subheading>Privacy Settings</Subheading>
          <br />
          <FormRowSingle>
            <Typography variant="body1" component="p" paragraph>
              Adjust the visibility of your email address to Community members{' '}
              <UnderlinedA
                href="https://community.bloggingfordevs.com/settings/profile"
                target="_blank"
              >
                here <ExternalLink size={12} />
              </UnderlinedA>
            </Typography>
          </FormRowSingle>
        </FormSection>
        <Button
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          disabled={formDisabled}
        >
          Save profile
        </Button>
      </ProfileForm>

      <ImageUploadDialog
        title={'Edit your Avatar'}
        src={user.user.avatarUrl}
        open={dialogOpen}
        onCancel={closeDialog}
        onUpload={async (blob) => {
          const filePath = `avatars/${shortid()}.jpg`;
          await storage().ref(filePath).put(blob);
          const avatarUrl = `https://storage.googleapis.com/bloggingfordevs.appspot.com/${filePath}`;
          await updateAvatarUrl(user.id, avatarUrl);
        }}
      >
        {({ onClose }) => (
          <ButtonWithPromise
            variant="outlined"
            color="primary"
            pending="Updating..."
            onClick={async () => {
              await updateAvatarUrl(
                user.id,
                `https://www.gravatar.com/avatar/${md5(user.user.email)}`
              );
              onClose();
            }}
          >
            Use Gravatar avatar
          </ButtonWithPromise>
        )}
      </ImageUploadDialog>
    </div>
  );
};

const YourAccount = () => {
  const { user } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  const manageSubscription = async () => {
    const url = await getBillingPortalUrl();
    window.open(url);
  };

  return (
    <GridSection>
      <GridSectionHeading>Your Account</GridSectionHeading>
      <GridSectionBody>
        <AdminButton
          onClick={() => {
            firebase
              .auth()
              .sendPasswordResetEmail(user!.user.email)
              .then(() => {
                enqueueSnackbar(
                  `A password reset email has been sent to ${user!.user.email}`,
                  { variant: 'success' }
                );
              });
          }}
        >
          Reset Password
        </AdminButton>{' '}
        <AdminButton
          onClick={() => {
            manageSubscription();
          }}
        >
          Manage Subscription
        </AdminButton>
        <AdminButton
          onClick={() => {
            logout();
          }}
        >
          Log out
        </AdminButton>
      </GridSectionBody>
    </GridSection>
  );
};

const WebsiteList = ({
  websites,
  onClick
}: {
  websites: Doc<Website>[];
  onClick: (w: Doc<Website>) => void;
}) => {
  if (!websites.length) {
    return (
      <Typography variant="body1">
        Click "Add blog" to add a blog to your account. It's optional, but
        useful :)
      </Typography>
    );
  }

  return (
    <div>
      {websites.map((w) => (
        <WebsitePreview key={w.id} blog={w} onClick={onClick} />
      ))}
    </div>
  );
};

export const YourBlogs = ({ user }: { user: CurrentUser }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [websites] = useWebsitesByUser(user.id);
  const EMPTY_WEBSITE: Doc<Website> = useMemo(() => {
    return {
      id: '',
      collection: '',
      data: {
        name: '',
        description: '',
        rssUrl: '',
        faviconUrl: '',
        url: '',
        userIds: [],
        etag: '',
        createdAt: firebase.firestore.Timestamp.now()
      }
    };
  }, []);
  const [currentWebsiteId, setCurrentWebsiteId] = useState<string | null>(null);
  const currentWebsite = websites
    ? websites.find((w) => w.id === currentWebsiteId) || null
    : null;
  const [open, setOpen] = useState(false);
  const mode = currentWebsite === null ? 'create' : 'edit';

  const onSubmit = async (website: Website) => {
    if (mode === 'create') {
      return createWebsite(website, user.id).then(() => {
        enqueueSnackbar('Website added!', { variant: 'default' });
        setOpen(false);
      });
    }
    if (mode === 'edit' && websites && currentWebsiteId) {
      return updateWebsite(currentWebsiteId, website).then(() => {
        enqueueSnackbar('Website updated!', { variant: 'default' });
        setOpen(false);
        setCurrentWebsiteId(null);
      });
    }
    return Promise.reject();
  };

  return (
    <GridSection>
      <GridSectionHeading>
        <span style={{ display: 'inline-block', marginRight: '6px' }}>
          Your Blogs
        </span>
        <MiniButton
          onClick={() => {
            setOpen(true);
          }}
        >
          Add blog
        </MiniButton>
      </GridSectionHeading>
      <GridSectionBody>
        {!!websites && (
          <WebsiteList
            websites={websites}
            onClick={(w: Doc<Website>) => {
              setCurrentWebsiteId(w.id);
              setOpen(true);
            }}
          />
        )}
      </GridSectionBody>
      {open && (
        <WebsiteDialog
          mode={mode}
          open={open}
          onClose={() => {
            setCurrentWebsiteId(null);
            setOpen(false);
          }}
          onSubmit={onSubmit}
          onRemove={async () => {
            currentWebsiteId && (await removeWebsite(currentWebsiteId));
            setCurrentWebsiteId(null);
            setOpen(false);
          }}
          website={currentWebsite || EMPTY_WEBSITE}
        />
      )}
    </GridSection>
  );
};

const IndexPage = () => {
  const { isAuthenticated, loadingUser, user } = useCurrentUser();

  return (
    <Layout>
      <SEO
        title="Profile"
        siteUrl="https://bloggingfordevs.com"
        description="Update your community and public profiles for Blogging for Devs"
        pathname="profile/"
        noIndex
      />
      <Wrapper style={{ maxWidth: '1000px' }}>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          style={{ textAlign: 'center', marginBottom: '5rem' }}
        >
          Welcome, {user ? toUserName(user.user) : '...'} 👋
        </Typography>
        {isAuthenticated && user && (
          <Grid>
            <Profile user={user} />
            <Admin>
              <YourBlogs user={user} />
              <YourAccount />
              <QuestionsGridSection />
            </Admin>
          </Grid>
        )}
        {!isAuthenticated && !loadingUser && (
          <Typography variant="body1">
            You need to log in to see this page
          </Typography>
        )}
      </Wrapper>
    </Layout>
  );
};

export default onlyOnClientSide(IndexPage);
