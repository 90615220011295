import styled from '../styled';

export const Highlight = styled('span')`
  color: ${(p) => p.theme.palette.primary.main};
  font-weight: 700;
  background-color: #69c0ff29;
  padding: 5px;
  border-radius: 3px;
`;

export const HighlightWhite = styled('span')`
  color: ${(p) => p.theme.palette.common.white};
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 5px;
  border-radius: 3px;
`;
