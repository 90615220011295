import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography
} from '@material-ui/core';
import { Link } from 'gatsby';
import React, { useMemo, useState } from 'react';
import {
  BookOpen,
  Key,
  Edit,
  Users,
  Menu,
  X,
  ChevronDown
} from 'react-feather';
import { BrandingA } from '../components/Branding';
import { useCurrentUser } from '../services/currentUser';
import styled from '../styled';
import { COLORS } from '../themes/colors';
import { useDialogState } from '../hooks/useDialogState';
import { SignupOrLoginDialog } from './LoginForm';

const ACTIVE_STYLE = {
  borderBottom: `3px solid ${COLORS.primary.main}`
};

export const NAV_HEIGHT = 64;
export const MOBILE_NAV_HEIGHT = 124;

const AVATAR_SIZE = 36;

const ProfileAvatar = styled(Avatar)`
  height: ${AVATAR_SIZE}px;
  width: ${AVATAR_SIZE}px;

  img {
    height: ${AVATAR_SIZE}px;
    width: ${AVATAR_SIZE}px;
  }
`;

const Nav = styled('nav')`
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #0d0f11;
  padding: 8px 16px;
  font-size: 16px;
  height: ${NAV_HEIGHT}px;
  z-index: 2;
  transition: opacity 0.2s ease-in;

  @media (max-width: 800px) {
    margin: 0;
    padding: 6px 16px;
    height: ${MOBILE_NAV_HEIGHT}px;
  }
`;

const BrandingWrapper = styled('div')`
  grid-area: branding;
  display: flex;
  align-items: center;

  a {
    display: inline-block;
    margin: 0 24px 0 0;

    @media (max-width: 800px) {
      margin: 0 auto;
    }
  }

  img {
    width: 256px;

    @media (max-width: 800px) {
      max-width: 100%;
      max-height: 30px;
    }
  }
`;

const MobileNav = styled('div')`
  display: none;
  grid-area: mobile-nav;

  svg {
    color: #8a94a6; // Matching Circle
  }

  @media (max-width: 800px) {
    display: flex;
    align-items: center;
  }
`;

const NavInner = styled('div')`
  display: grid;
  grid-template-columns: 284px 1fr 250px;
  grid-template-areas: 'branding nav actions';
  grid-column-gap: 16px;
  grid-row-gap: 8px;
  max-width: 1280px;
  margin: 0 auto;

  @media (max-width: 800px) {
    grid-template-columns: 40px 1fr 40px;
    grid-template-areas:
      'mobile-nav branding actions'
      'nav nav nav';
    grid-column-gap: 20px;
  }
`;

const UserSection = styled('div')`
  grid-area: actions;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Links = styled('ul')`
  list-style-type: none;
  grid-area: nav;
  display: flex;
  align-items: center;
  position: relative;
  left: -4px;
  top: -2px;
  margin: 0;
  padding: 0;

  @media (max-width: 800px) {
    text-align: center;
    margin: 0 auto;
  }

  @media (max-width: 600px) {
    & > li:not(:last-of-type) {
      margin-right: 12px !important;
    }
  }

  a {
    font-weight: 500;
    color: #c9d1da;
  }

  & > li {
    display: inline-block;

    &:not(:last-of-type) {
      margin-right: 36px;
    }
  }
`;

// More menu hardcoded / not using Material components
// for precise replication on Circle

const MoreParent = styled('a')`
  display: flex;
  align-items: center;
`;

const DownArrow = styled(ChevronDown)`
  margin-left: 6px;
`;

const MoreMenuContainer = styled('div')`
  position: relative;
  padding: 12px 0;
  cursor: pointer;

  &:hover {
    .bfd-more-submenu {
      display: block;
      opacity: 1;
    }
  }
`;

const MoreSubMenu = styled('div')`
  display: none;
  opacity: 0;
  position: absolute;
  top: 48px;
  left: -129px;
  width: 312px;
  background-color: #000000;
  border: 1px solid #23262b;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    padding: 0;
  }

  a {
    display: grid;
    grid-template-columns: 50px 1fr;
    grid-column-gap: 16px;
    align-items: center;
    padding: 18px 24px;
  }

  a:hover {
    background-color: #15181b;
  }

  .bfd-more-submenu__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 100%;
  }

  .bfd-more-submenu__icon--guides {
    background-color: #ffc53d;
    color: #ad6800;
  }

  .bfd-more-submenu__icon--blog {
    background-color: #1890ff;
    color: #0050b3;
  }

  .bfd-more-submenu__icon--interviews {
    background-color: #ff4d4f;
    color: #a8071a;
  }

  .bfd-more-submenu__icon--garden {
    background-color: #73d13d;
    color: #237804;
  }

  .bfd-more-submenu__meta {
    display: block;
    text-align: left;
  }

  .bfd-more-submenu__title {
    display: block;
    color: #e3e9f0;
    font-weight: 600;
    font-size: 16px;
  }

  .bfd-more-submenu__description {
    display: block;
    color: #767e87;
    font-size: 13px;
  }
`;

const MoreMenu = () => {
  return (
    <MoreMenuContainer>
      <MoreParent>
        More <DownArrow size={18} />
      </MoreParent>
      <MoreSubMenu className="bfd-more-submenu">
        <ul>
          <li>
            <Link to="/guides/">
              <span className="bfd-more-submenu__icon bfd-more-submenu__icon--guides">
                <Key size={24} />
              </span>
              <span className="bfd-more-submenu__meta">
                <span className="bfd-more-submenu__title">Guides</span>
                <span className="bfd-more-submenu__description">
                  Follow practical guides designed for devs who blog
                </span>
              </span>
            </Link>
          </li>
          <li>
            <a href="https://community.bloggingfordevs.com/c/resource-library">
              <span className="bfd-more-submenu__icon bfd-more-submenu__icon--garden">
                <BookOpen size={24} />
              </span>
              <span className="bfd-more-submenu__meta">
                <span className="bfd-more-submenu__title">
                  Resource Library
                </span>
                <span className="bfd-more-submenu__description">
                  Collection of the best resources and discussions in the
                  community
                </span>
              </span>
            </a>
          </li>
          <li>
            <Link to="/blog/">
              <span className="bfd-more-submenu__icon bfd-more-submenu__icon--blog">
                <Edit size={24} />
              </span>
              <span className="bfd-more-submenu__meta">
                <span className="bfd-more-submenu__title">Blog</span>
                <span className="bfd-more-submenu__description">
                  Read and discuss free and members-only articles
                </span>
              </span>
            </Link>
          </li>
          <li>
            <Link to="/interviews/">
              <span className="bfd-more-submenu__icon bfd-more-submenu__icon--interviews">
                <Users size={24} />
              </span>
              <span className="bfd-more-submenu__meta">
                <span className="bfd-more-submenu__title">Interviews</span>
                <span className="bfd-more-submenu__description">
                  Learn from successful developers who blog and write online
                </span>
              </span>
            </Link>
          </li>
        </ul>
      </MoreSubMenu>
    </MoreMenuContainer>
  );
};

const MemberLoginWrapper = styled('div')`
  position: absolute;
  right: 24px;
  top: 12px;
  color: ${(p) => p.theme.palette.grey[400]};
  font-size: 12px;
  z-index: 5;

  a {
    display: inline-block;
    margin-right: 9px;
  }
`;

const MemberLoginButton = styled(Button)`
  background-color: ${(p) => p.theme.palette.grey[700]};
  color: ${(p) => p.theme.palette.grey[200]};
  font-size: 14px;
  padding: 8px 16px;

  &:first-of-type {
    margin-right: ${(p) => p.theme.spacing(1)}px;
  }

  &:hover {
    background-color: ${(p) => p.theme.palette.grey[900]};
  }
`;

export const Header = () => {
  const { isAuthenticated, loadingUser, user } = useCurrentUser();
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  const isAdmin = !!user?.isAdmin;
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const isMigrating = useMemo(() => {
    if (isAuthenticated || typeof window === 'undefined') {
      return false;
    }
    const searchParams = new URLSearchParams(window.location.search);
    return (
      searchParams.get('ref') === 'circle' &&
      window.location.pathname !== '/migrate/'
    );
  }, [isAuthenticated]);

  return (
    <>
      {!isAuthenticated && !loadingUser && false && (
        <MemberLoginWrapper>
          <MemberLoginButton
            onClick={() => {
              openDialog();
            }}
          >
            Member Login
          </MemberLoginButton>
        </MemberLoginWrapper>
      )}
      <Nav style={{ opacity: isAuthenticated || isMigrating ? 1 : 0 }}>
        <NavInner>
          <MobileNav>
            <IconButton
              onClick={() => {
                setMobileMenuOpen(true);
              }}
            >
              <Menu size={22} />
            </IconButton>
          </MobileNav>
          <BrandingWrapper>
            <BrandingA href="https://community.bloggingfordevs.com">
              <img src="/images/branding.png" />
            </BrandingA>
          </BrandingWrapper>
          {isMigrating && !isAuthenticated && (
            <Links>
              <li>
                <Link to="/events/" activeStyle={ACTIVE_STYLE}>
                  Events
                </Link>
              </li>
              <li>
                <Link to="/feed/" activeStyle={ACTIVE_STYLE}>
                  Feed
                </Link>
              </li>
              <li>
                <MoreMenu />
              </li>
            </Links>
          )}
          {!isMigrating && isAuthenticated && (
            <Links>
              <li>
                <Link to="/events/" activeStyle={ACTIVE_STYLE}>
                  Events
                </Link>
              </li>
              <li>
                <Link to="/feed/" activeStyle={ACTIVE_STYLE}>
                  Feed
                </Link>
              </li>
              <li>
                <MoreMenu />
              </li>
              {isAdmin && (
                <li>
                  <Link to="/admin/" activeStyle={ACTIVE_STYLE}>
                    Admin
                  </Link>
                </li>
              )}
            </Links>
          )}
          <UserSection>
            <Link to="/profile/" style={{ padding: '8px', display: 'block' }}>
              <ProfileAvatar src={user?.user.avatarUrl} />
            </Link>
          </UserSection>
        </NavInner>
        {mobileMenuOpen && (
          <Dialog
            fullScreen
            open={mobileMenuOpen}
            onClose={() => {
              setMobileMenuOpen(false);
            }}
          >
            <DialogContent>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '24px'
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  style={{ fontWeight: 600 }}
                >
                  Blogging for Devs
                </Typography>
                <IconButton
                  onClick={() => {
                    setMobileMenuOpen(false);
                  }}
                >
                  <X size={24} />
                </IconButton>
              </div>
              Coming soon
            </DialogContent>
          </Dialog>
        )}
      </Nav>
      <SignupOrLoginDialog
        open={dialogOpen}
        onClose={closeDialog}
        initialState="login"
      />
    </>
  );
};
