import { FUNCTIONS } from '../constants';
import { callFirebaseFunction } from './cf';

export const getBillingPortalUrl = async () => {
  return callFirebaseFunction<{
    session: {
      url: string;
    };
  }>(FUNCTIONS.createCustomerPortalSession).then(({ session }) => {
    return session.url;
  });
};
