import { Button, CircularProgress, Dialog, TextField } from '@material-ui/core';
import firebase from 'firebase/app';
import React, { useState } from 'react';
import { login } from '../services/auth';
import styled from '../styled';
import { WarningMessage, SuccessMessage } from './Alerts';
import { Subscription } from './Subscribe';

const FormActionsFooter = styled('div')`
  text-align: center;
`;

const FormActionButton = styled(Button)`
  color: ${(p) => p.theme.palette.grey['300']};
  margin: 0 auto;
`;

const FormRow = styled('div')`
  margin: ${(p) => p.theme.spacing(2)}px auto;
`;

const Loader = styled(CircularProgress)`
  width: 20px !important;
  height: 20px !important;
  margin-right: ${(p) => p.theme.spacing(1)}px;
  svg {
    color: inherit;
  }
`;

const FormWrapper = styled('form')`
  margin: ${(p) => p.theme.spacing(6)}px auto 0;
`;

export const SignupOrLogin = ({
  initialState,
  onLoginSuccess,
  onChangeState,
  onSignupSuccess
}: {
  initialState: 'signup' | 'login';
  onLoginSuccess?: () => any;
  onChangeState: (newValue: 'signup' | 'login') => any;
  onSignupSuccess?: () => any;
}) => {
  return (
    <div>
      <div>
        {initialState === 'login' ? (
          <LoginForm
            onChangeState={() => {
              onChangeState('signup');
            }}
            onSuccess={onLoginSuccess}
          />
        ) : (
          <Subscription
            onChangeState={() => {
              onChangeState('login');
            }}
            onSuccess={onSignupSuccess}
          />
        )}
      </div>
    </div>
  );
};

export const SignupOrLoginDialog = ({
  open,
  onClose,
  initialState
}: {
  open: boolean;
  onClose: () => void;
  initialState: 'signup' | 'login';
}) => {
  const [state, setState] = useState(initialState);

  const handleChange = (newValue: 'signup' | 'login') => {
    setState(newValue);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="body"
      maxWidth={state === 'login' ? 'xs' : 'md'}
      fullWidth
      PaperProps={{
        elevation: 8,
        style: { padding: '24px 36px' }
      }}
    >
      <SignupOrLogin
        initialState={state}
        onChangeState={handleChange}
        onLoginSuccess={onClose}
        onSignupSuccess={() => {}}
      />
    </Dialog>
  );
};

export const LoginForm = ({
  onChangeState,
  onSuccess
}: {
  onChangeState: (newValue: 'signup' | 'login') => any;
  onSuccess?: () => void;
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [resetPassword, setResetPassword] = useState(false);
  const [resetPasswordEmailSent, setResetPasswordEmailSent] = useState(false);

  return (
    <FormWrapper
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setLoading(true);
        setErrorMsg('');
        setResetPasswordEmailSent(false);

        if (resetPassword) {
          firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(() => {
              setLoading(false);
              setResetPasswordEmailSent(true);
              setResetPassword(false);
            })
            .catch((resp) => {
              const msg = resp.message;
              setLoading(false);
              setErrorMsg(msg);
            });
        } else {
          login(email, password)
            .then(() => {
              setLoading(false);
              if (onSuccess) {
                onSuccess();
              }
            })
            .catch((resp) => {
              const msg = resp.message;
              setLoading(false);
              setErrorMsg(msg);
            });
        }
      }}
    >
      {resetPasswordEmailSent ? (
        <SuccessMessage style={{ marginBottom: '36px' }}>
          An email to reset your password has been sent!
        </SuccessMessage>
      ) : (
        <WarningMessage style={{ marginBottom: '36px' }}>
          Not a member yet?{' '}
          <a href="https://bloggingfordevs.com/pro/" target="_blank">
            Click here
          </a>{' '}
          to join us :)
        </WarningMessage>
      )}
      <FormRow>
        <TextField
          type="email"
          variant="outlined"
          label="Email"
          value={email}
          fullWidth
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          required
        />
      </FormRow>
      {!resetPassword && (
        <FormRow>
          <TextField
            type="password"
            variant="outlined"
            label="Password"
            value={password}
            fullWidth
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            required
          />
        </FormRow>
      )}
      <FormRow>
        <Button
          size="large"
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          style={{
            padding: '12px 24px',
            fontSize: '16px',
            fontWeight: 700
          }}
          disabled={!email || !password || loading}
        >
          {loading && <Loader />}
          {resetPassword ? 'Reset your password' : 'Log in'}
        </Button>
      </FormRow>
      {errorMsg.length > 0 && <WarningMessage>{errorMsg}</WarningMessage>}
      <FormActionsFooter>
        {resetPassword ? (
          <FormActionButton
            onClick={() => {
              setErrorMsg('');
              setResetPassword(false);
            }}
          >
            Sign in to your account
          </FormActionButton>
        ) : (
          <FormActionButton
            onClick={() => {
              setResetPassword(true);
            }}
          >
            Forgot your password?
          </FormActionButton>
        )}
      </FormActionsFooter>
    </FormWrapper>
  );
};
