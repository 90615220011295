import { Typography } from '@material-ui/core';
import styled from '../styled';

export const Subheading = styled(Typography)`
  color: ${(p) => p.theme.palette.grey['400']};
  letter-spacing: 2px;
  font-weight: 700;
  font-size: ${(p) => p.theme.typography.body1.fontSize};
  line-height: ${(p) => p.theme.typography.body1.lineHeight};
  text-transform: uppercase;

  display: flex;
  align-items: center;

  > :not(:first-of-type) {
    margin-left: ${(p) => p.theme.spacing(2)}px;
  }
`;

export const PrimarySubheading = styled(Subheading)`
  color: ${(p) => p.theme.palette.primary.main};
`;
