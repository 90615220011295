import { Button } from '@material-ui/core';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import { logout } from '../services/auth';
import { useCurrentUser } from '../services/currentUser';
import styled from '../styled';
import { SignupOrLoginDialog } from './LoginForm';

const Chip = styled('span')`
  display: inline-block;
  height: 24px;
  line-height: 24px;
  background-color: #1890ff;
  color: #e6f7ff;
  padding: 0px 8px;
  border-radius: 8px;
  margin-left: 6px;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  top: -2px;
`;

const FooterWrapper = styled('div')`
  font-size: 18px;
  line-height: 32px;
  margin: 0 -12px -12px;
  padding: ${(p) => p.theme.spacing(8)}px ${(p) => p.theme.spacing(2)}px
    ${(p) => p.theme.spacing(4)}px;
  background-color: #0d0f11;

  @media (max-width: 800px) {
    margin: 0;
  }

  .view-all {
    color: ${(p) => p.theme.palette.grey[400]};
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    border: 0;
  }

  button {
    color: #9da6b0;
    text-transform: uppercase;
    font-size: 18px;
    padding: 0;
  }
`;

const Inner = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  margin: 0 auto;
  max-width: 1150px;
  padding: ${(p) => p.theme.spacing(2)}px;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const List = styled('ul')`
  list-style-type: none;
  font-size: 16px;
  line-height: 36px;
  padding: 0;
  margin-top: 8px;

  li {
    margin-bottom: 4px;
  }

  a {
    color: ${(p) => p.theme.palette.grey[300]};

    &:hover {
      color: ${(p) => p.theme.palette.grey[200]};
    }
  }
`;

const Wrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 12px;
  border-radius: 8px;

  @media (max-width: 900px) {
    margin-top: 1rem;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const ListHeading = styled('div')`
  color: ${(p) => p.theme.palette.grey[200]};
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;

  @media (max-width: 500px) {
    margin-top: 1rem;
  }
`;

const Title = styled(Link)`
  display: block;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: ${(p) => p.theme.palette.primary.main};
`;

const ByLine = styled('span')`
  display: block;
  margin-top: ${(p) => p.theme.spacing(2)}px;
  color: ${(p) => p.theme.palette.grey[400]};
`;

const TwitterButton = styled('a')`
  background-color: #1b95e0;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  padding: 0px 8px;
  border-radius: 4px;
  margin-left: 4px;
`;

const LinkList = styled('ul')`
  color: ${(p) => p.theme.palette.grey[400]};
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    padding: 0;
    margin-right: ${(p) => p.theme.spacing(3)}px;

    a {
      color: ${(p) => p.theme.palette.grey[400]};

      &:hover {
        color: ${(p) => p.theme.palette.grey[200]};
      }
    }
  }
`;

export const Footer = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { isAuthenticated } = useCurrentUser();

  return (
    <FooterWrapper>
      <Inner>
        <div>
          <Title to="/">Blogging for Devs</Title>
          <ByLine>
            Made by{' '}
            <TwitterButton
              href="https://twitter.com/monicalent"
              target="_blank"
              rel="noopener"
            >
              @monicalent
            </TwitterButton>
          </ByLine>
          <p>
            Learn how to grow your blog as a developer without an existing
            audience through great writing and SEO.
          </p>
          <LinkList>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/contact/">Contact</Link>
            </li>
            <li>
              <Link to="/impressum/">Impressum</Link>
            </li>
            <li>
              <Link to="/sponsor/">Become a Sponsor</Link>
            </li>
          </LinkList>
        </div>
        <Wrapper>
          <div>
            <ListHeading>Resources</ListHeading>
            <List>
              <li>
                <Link to="/">Free Course</Link>
              </li>
              <li>
                <Link to="/blog/">Blog</Link>
              </li>
              <li>
                <Link to="/interviews/">Interviews</Link>
              </li>
              <li>
                <a href="https://seofordevs.com">SEO for Developers</a>
              </li>
              <li>
                <Link to="/trends/">Developer Blogs</Link>
              </li>
              <li>
                <Link to="/" className="view-all">
                  Home
                </Link>
              </li>
            </List>
          </div>
          <div>
            <ListHeading>Community</ListHeading>
            <List>
              {!isAuthenticated && (
                <li>
                  <Link to="/pro/">Join Us</Link> <Chip>New</Chip>
                </li>
              )}
              {isAuthenticated && (
                <li>
                  <a
                    href="https://community.bloggingfordevs.com"
                    target="_blank"
                  >
                    Go to Community
                  </a>
                </li>
              )}
              <li>
                <Link to="/events/">Live Events</Link>
              </li>
              <li>
                <Link to="/reset-password/">Reset Password</Link>
              </li>
              {isAuthenticated ? (
                <Button
                  variant="text"
                  style={{
                    minWidth: 'auto',
                    color: '#9da6b0',
                    fontSize: '14px',
                    fontWeight: 700
                  }}
                  onClick={() => {
                    logout();
                  }}
                >
                  LOG OUT
                </Button>
              ) : (
                <Button
                  variant="text"
                  style={{
                    minWidth: 'auto',
                    color: '#9da6b0',
                    fontSize: '14px',
                    fontWeight: 700
                  }}
                  onClick={() => {
                    setDialogOpen(true);
                  }}
                >
                  LOG IN
                </Button>
              )}
            </List>
          </div>
          <div>
            <ListHeading>Discover</ListHeading>
            <List>
              <li>
                <Link to="/javascript-blogs/">JavaScript Blogs</Link>
              </li>
              <li>
                <Link to="/node-blogs/">Node.js Blogs</Link>
              </li>
              <li>
                <Link to="/ruby-blogs/">Ruby Blogs</Link>
              </li>
              <li>
                <Link to="/react-blogs/">React.js Blogs</Link>
              </li>
              <li>
                <Link to="/trends/" className="view-all">
                  View All
                </Link>
              </li>
            </List>
          </div>
        </Wrapper>
      </Inner>
      <SignupOrLoginDialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
        initialState="login"
      />
    </FooterWrapper>
  );
};
