import { Typography } from '@material-ui/core';
import React from 'react';
import ReactDropzone, { DropzoneProps } from 'react-dropzone';
import { UploadCloud as IconUpload } from 'react-feather';
import styled from '../styled';

type Props = {
  label?: string;
  note?: string;
  onDrop: DropzoneProps['onDrop'];
};

export const DROPZONE_HEIGHT = 200;

const Container = styled('div')`
  width: 100%;
  height: ${DROPZONE_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed ${(p) => p.theme.palette.primary.main};
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  color: ${(p) => p.theme.palette.primary.main};
  cursor: pointer;
`;

const DropzoneLabel = styled(Typography)`
  display: block;
  text-align: center;
  font-weight: 700 !important;
  padding: ${(p) => p.theme.spacing(2)}px;
`;

const DropzoneNote = styled(Typography)`
  margin: 12px auto !important;
  display: block;
  text-align: center;
  font-weight: 500 !important;
  max-width: 300px;
  font-size: 13px !important;
`;

export const Dropzone: React.FC<Props> = ({
  label = <>Drag and drop your files here, or click to select them</>,
  note = '',
  onDrop
}) => {
  return (
    <ReactDropzone onDrop={onDrop}>
      {({ getRootProps, getInputProps }) => (
        <Container {...getRootProps({ refKey: 'ref' })}>
          <input {...getInputProps()} />
          <DropzoneLabel>
            <IconUpload size={36} />
            <br />
            {label}
            {note && <DropzoneNote>{note}</DropzoneNote>}
          </DropzoneLabel>
        </Container>
      )}
    </ReactDropzone>
  );
};
